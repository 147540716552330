var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-container',{attrs:{"fluid":""}},[_c('b-row',[_c('b-col',[_c('b-card',[_c('div',{staticClass:"ml-2"},[_c('b-button',{staticClass:"btn-tour-skip",attrs:{"variant":"primary"},on:{"click":function($event){return _vm.populateCategory('Set Category', {})}}},[_c('FeatherIcon',{attrs:{"icon":"PlusCircleIcon"}}),_c('span',{staticClass:"mx-2 align-middle"},[_vm._v("Set Category")])],1),_c('b-button',{staticClass:"btn-tour-skip ml-2",attrs:{"variant":"primary"},on:{"click":function($event){return _vm.getCoverages()}}},[_c('FeatherIcon',{attrs:{"icon":"RepeatIcon"}}),_c('span',{staticClass:"mx-2 align-middle"},[_vm._v("Reload Table Data")])],1)],1),_c('MyTable',{attrs:{"columns":_vm.columns,"rows":_vm.rows,"is-loading":_vm.isLoading},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var props = ref.props;
return [(props.column.field == 'action')?_c('span',[_c('b-dropdown',{attrs:{"variant":"link","toggle-class":"text-decoration-none","no-caret":""},scopedSlots:_vm._u([{key:"button-content",fn:function(){return [_c('feather-icon',{staticClass:"text-body align-middle mr-25",attrs:{"icon":"MoreVerticalIcon","size":"16"}})]},proxy:true}],null,true)},[_c('b-dropdown-item',{on:{"click":function($event){return _vm.populateCategory('Edit Category', props.row)}}},[_c('feather-icon',{staticClass:"mr-50",attrs:{"icon":"Edit2Icon"}}),_c('span',[_vm._v("Edit")])],1),_c('b-dropdown-item',{on:{"click":function($event){$event.preventDefault();return _vm.deleteCoverage(props.row)}}},[_c('feather-icon',{staticClass:"mr-50",attrs:{"icon":"TrashIcon"}}),_c('span',[_vm._v("Delete")])],1)],1)],1):_vm._e()]}}])})],1)],1)],1),_c('b-modal',{attrs:{"id":"categoryModal","title":_vm.modalTitle,"hide-footer":"","no-close-on-backdrop":"","centered":"","size":"lg"}},[_c('ValidationObserver',{ref:"category"},[_c('b-row',{staticClass:"mb-2"},[_c('b-col',{attrs:{"cols":"6"}},[_c('ValidationProvider',{attrs:{"name":"Start state","vid":"startState","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('vSelect',{attrs:{"options":_vm.states,"reduce":function (option) { return option.id; },"getOptionLabel":function (option) { return option.state_name; },"placeholder":"Select Start State"},model:{value:(_vm.form.startState),callback:function ($$v) {_vm.$set(_vm.form, "startState", $$v)},expression:"form.startState"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-col',{attrs:{"cols":"6"}},[_c('ValidationProvider',{attrs:{"name":"End state","vid":"endState","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('vSelect',{attrs:{"options":_vm.states,"reduce":function (option) { return option.id; },"getOptionLabel":function (option) { return option.state_name; },"placeholder":"Select End State"},model:{value:(_vm.form.endState),callback:function ($$v) {_vm.$set(_vm.form, "endState", $$v)},expression:"form.endState"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-row',{staticClass:"mb-2"},[_c('b-col',{attrs:{"cols":"6"}},[_c('ValidationProvider',{attrs:{"name":"Category","vid":"category","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('vSelect',{attrs:{"reduce":function (option) { return option.id; },"options":_vm.categories,"placeholder":"Select Category"},model:{value:(_vm.form.category),callback:function ($$v) {_vm.$set(_vm.form, "category", $$v)},expression:"form.category"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-row',{staticClass:"mb-2"},[_c('b-col',[_c('b-button',{attrs:{"variant":"gradient-primary","type":"submit"},on:{"click":function($event){return _vm.submitCategory()}}},[_vm._v(" SUBMIT ")])],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }