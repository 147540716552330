<template>
  <b-container fluid>
    <b-row>
      <b-col>
        <b-card>
          <div class="ml-2">
            <b-button
              @click="populateCategory('Set Category', {})"
              variant="primary"
              class="btn-tour-skip"
            >
              <FeatherIcon icon="PlusCircleIcon" />
              <span class="mx-2 align-middle">Set Category</span>
            </b-button>
            <b-button
              @click="getCoverages()"
              variant="primary"
              class="btn-tour-skip ml-2"
            >
              <FeatherIcon icon="RepeatIcon" />
              <span class="mx-2 align-middle">Reload Table Data</span>
            </b-button>
          </div>

          <MyTable :columns="columns" :rows="rows" :is-loading="isLoading">
            <template v-slot="{ props }">
              <span v-if="props.column.field == 'action'">
                <b-dropdown
                  variant="link"
                  toggle-class="text-decoration-none"
                  no-caret
                >
                  <template v-slot:button-content>
                    <feather-icon
                      icon="MoreVerticalIcon"
                      size="16"
                      class="text-body align-middle mr-25"
                    />
                  </template>
                  <b-dropdown-item
                    @click="populateCategory('Edit Category', props.row)"
                  >
                    <feather-icon icon="Edit2Icon" class="mr-50" />
                    <span>Edit</span>
                  </b-dropdown-item>
                  <b-dropdown-item @click.prevent="deleteCoverage(props.row)">
                    <feather-icon icon="TrashIcon" class="mr-50" />
                    <span>Delete</span>
                  </b-dropdown-item>
                </b-dropdown>
              </span>
            </template>
          </MyTable>
        </b-card>
      </b-col>
    </b-row>
    <b-modal
      id="categoryModal"
      :title="modalTitle"
      hide-footer
      no-close-on-backdrop
      centered
      size="lg"
    >
      <ValidationObserver ref="category">
        <b-row class="mb-2">
          <b-col cols="6">
            <ValidationProvider
              v-slot="{ errors }"
              name="Start state"
              vid="startState"
              rules="required"
            >
              <vSelect
                v-model="form.startState"
                :options="states"
                :reduce="(option) => option.id"
                :getOptionLabel="(option) => option.state_name"
                placeholder="Select Start State"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </ValidationProvider>
          </b-col>
          <b-col cols="6">
            <ValidationProvider
              v-slot="{ errors }"
              name="End state"
              vid="endState"
              rules="required"
            >
              <vSelect
                v-model="form.endState"
                :options="states"
                :reduce="(option) => option.id"
                :getOptionLabel="(option) => option.state_name"
                placeholder="Select End State"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </ValidationProvider>
          </b-col>
        </b-row>
        <b-row class="mb-2">
          <b-col cols="6">
            <ValidationProvider
              v-slot="{ errors }"
              name="Category"
              vid="category"
              rules="required"
            >
              <vSelect
                v-model="form.category"
                :reduce="(option) => option.id"
                :options="categories"
                placeholder="Select Category"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </ValidationProvider>
          </b-col>
        </b-row>
        <b-row class="mb-2">
          <b-col>
            <b-button
              variant="gradient-primary"
              type="submit"
              @click="submitCategory()"
            >
              SUBMIT
            </b-button>
          </b-col>
        </b-row>
      </ValidationObserver>
    </b-modal>
  </b-container>
</template>
<script>
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { required } from "@validations";
import MyTable from "@/views/components/MyTable.vue";
import vSelect from "vue-select";
export default {
  components: {
    MyTable,
    ValidationProvider,
    ValidationObserver,
    vSelect,
  },
  data() {
    return {
      isLoading: false,
      isEditing: false,
      categories: [],
      states: [],
      rows: [],
      form: {
        id: "",
        startState: "",
        endState: "",
        category: "",
      },
      pageLength: 5,
      searchTerm: "",
      exportType: "",
      modalTitle: "",
      columns: [
        {
          label: "Start State",
          field: "start_state.state_name",
        },
        {
          label: "End State",
          field: "end_state.state_name",
        },
        {
          label: "Category",
          field: "category.name",
        },
        {
          label: "Action",
          field: "action",
        },
      ],
    };
  },
  created() {
    this.getCategories();
    this.getStates();
    this.getCoverages();
  },
  methods: {
    generateAddCoreList() {
      this.$refs.generateAddCoreList.show();
    },

    populateCategory(title, row) {
      this.modalTitle = title;
      this.form.id = row.id || "";
      this.form.startState = row.start_state ? row.start_state.id : "" || "";
      this.form.endState = row.end_state ? row.end_state.id : "" || "";
      this.form.category = row.category ? row.category.id : "" || "";
      this.isEditing = this.form.id != "";

      this.$bvModal.show("categoryModal");
    },

    async getStates() {
      const response = await this.$http.get("state");
      if (response.data.status === false) {
        this.$swal({
          title: "Error !",
          icon: "warning",
          text: response.data.message,
          confirmButtonText: "OK",
          customClass: {
            confirmButton: "btn btn-danger",
          },
          buttonsStyling: false,
        });
        this.isLoading = false;
        return;
      }

      this.states = response.data.data.map((item) => ({
        ...item,
        label: item.state_name,
      }));
    },

    async getCategories() {
      const response = await this.$http.get("get_location_category");
      if (response.data.status === false) {
        this.$swal({
          title: "Error !",
          icon: "warning",
          text: response.data.message,
          confirmButtonText: "OK",
          customClass: {
            confirmButton: "btn btn-danger",
          },
          buttonsStyling: false,
        });
        this.isLoading = false;
        return;
      }

      this.categories = response.data.data.map((item) => ({
        ...item,
        label: item.category,
      }));
    },

    async getCoverages() {
      this.isLoading = true;
      const response = await this.$http.get("postal_category");
      if (response.data.status === false) {
        this.$swal({
          title: "Error !",
          icon: "warning",
          text: response.data.message,
          confirmButtonText: "OK",
          customClass: {
            confirmButton: "btn btn-danger",
          },
          buttonsStyling: false,
        });
      }
      this.rows = response.data.data;
      this.isLoading = false;
    },

    getDefaultToast() {
      return {
        position: "top-right",
        timeout: 5000,
        closeOnClick: true,
        pauseOnFocusLoss: true,
        pauseOnHover: true,
        draggable: true,
        draggablePercent: 0.6,
        showCloseButtonOnHover: false,
        hideProgressBar: true,
        closeButton: "button",
        icon: true,
        rtl: false,
      };
    },

    async submitCategory() {
      this.isLoading = true;
      const isValid = await this.$refs.category.validate();
      if (!isValid) {
        this.isLoading = false;
        return;
      }
      this.$bvModal.hide("categoryModal");
      if (this.isEditing) {
        this.$http
          .put(`postal_category/${this.form.id}`, this.form)
          .then((response) => {
            if (response.data.status == false) {
              for (const field in response.data.errors) {
                response.data.errors[field].forEach((message) => {
                  this.$toast.error(message, this.getDefaultToast());
                });
              }
            } else {
              this.$toast.success(
                response.data.message,
                this.getDefaultToast()
              );
            }
            this.getCoverages();
            this.form = {};
            this.isEditing = "";
            this.isLoading = false;
          })
          .catch((error) => {
            this.$swal({
              title: "Error !",
              icon: "warning",
              text: error,
              confirmButtonText: "OK",
              customClass: {
                confirmButton: "btn btn-danger",
              },
              buttonsStyling: false,
            });
            this.isLoading = false;
          });
      } else {
        this.$http
          .post("postal_category", this.form)
          .then((response) => {
            if (response.data.status == false) {
              for (const field in response.data.errors) {
                response.data.errors[field].forEach((message) => {
                  this.$toast.error(message, this.getDefaultToast());
                });
              }
            } else {
              this.$toast.success(
                response.data.message,
                this.getDefaultToast()
              );
            }
            this.getCoverages();
            this.form = {};
            this.isEditing = "";
            this.isLoading = false;
          })
          .catch((error) => {
            this.$swal({
              title: "Error !",
              icon: "warning",
              text: JSON.stringify(error),
              confirmButtonText: "OK",
              customClass: {
                confirmButton: "btn btn-danger",
              },
              buttonsStyling: false,
            });
            this.isLoading = false;
          });
      }
    },

    deleteCoverage(row) {
      this.$swal({
        title: "Are you sure?",
        text: row.name,
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Yes, delete it!",
        reverseButtons: true,
        customClass: {
          confirmButton: "btn btn-primary ml-1",
          cancelButton: "btn btn-outline-danger",
        },
        buttonsStyling: false,
      }).then(async (result) => {
        if (result.isConfirmed) {
          const response = await this.$http.delete(`postal_category/${row.id}`);
          if (response.data.status === true) {
            this.getCoverages();
            this.$swal({
              icon: "success",
              title: "Deleted!",
              text: response.data.message,
              customClass: {
                confirmButton: "btn btn-success",
              },
            });
          }
        }
      });
    },
  },
};
</script>

<style lang="scss">
@import "src/assets/scss/style.scss";
</style>
